@font-face {
    font-family: 'Abraham-Regular';
    src: local('Abraham-Regular'), url("./Abraham-Regular.ttf") format('truetype');
    /* font-weight: bold; */
}

* {
    font-family: 'Abraham-Regular' !important;
    letter-spacing: 0.06rem;

}
h1{
    text-align: center;
}

.middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;

}

.flex.sb {
    justify-content: space-between;
}

.flex.st {
    justify-content: start;
}

.flex.col {
    flex-direction: column;
}

.container {
    padding: 3%;
}

.outer-div {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    overflow: auto;
    z-index: 1;
    left: 0;
    top: 0;
}

/* .avatar{
    margin: 10px;
} */

.unmargin {
    margin: 0;
}

#root {
    /* background-color: #0e86d4; */
    /* background-image: url(background.png); */
    background-repeat: repeat;
    min-height: 100svh;
}

article {
    /* background-image: url(background2.png); */
    background-repeat: repeat;
    backdrop-filter: blur(5px);
    background-color: #14141485;
    /* background-color: rgba(20, 20, 20, 0.75); */
    /* filter: blur(5px); */
    /* min-width: 80%; */
    /* min-width: 500px !important; */
    /* animation: shadow-drop-2-center-and-scale-up-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important; */

}

dialog>article {
    opacity: 1;
}

small {
    color: aliceblue;
}

.outline[role] {
    background-color: rgb(26, 26, 26) !important;
    /* opacity: 0.8; */
}

.tabs * {
    border-bottom: none;
}

th,
td {
    text-align: center;
    padding: 1 !important;
}

th[scope='col'] {
    vertical-align: bottom;
}

th[scope='row'] {
    width: 20px;
    font-size: 2em;
}

@keyframes shadow-drop-2-center-and-scale-up-center {
    0% {
        transform: translateZ(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(0.5);

    }

    100% {
        transform: translateZ(50px);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {}

    100% {}
}