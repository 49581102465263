/* canvas { */
    /* border: 4px black solid; */
    /* border-radius: 10px; */
    /* width: 100vw; */
    /* min-height: 80vh; */
    /* max-width: 100%;
    max-height: 90%; */
    /* margin: 20px 10%; */
    /* height: auto !important; */
    /* box-sizing: content-box; */
    /* aspect-ratio: 16/9; */
    /* height: 100%; */
    /* width: 100%; */
/* } */

.canvas-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;

}
