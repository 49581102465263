@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
}

/* 
.context {
    width: 100%;
    position: absolute;
    top:50vh;
    z-index: 1;
} */

/* .context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
} */


/* .area{
    background: #4e54c8; 
    position: fixed;
    width: 100%;
    height:100vh;
} */

.circles{
    /* background: #967537;  */
    /* background: #4ec88f;  */
    background: #4e54c8; 

    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100lvh;
    overflow: hidden;
    z-index: -1;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -300px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 300px;
    height: 300px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 200px;
    height: 200px;
    animation-delay: 2s;
    animation-duration: 20s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 200px;
    height: 200px;
    animation-delay: 3s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 300px;
    height: 300px;
    animation-delay: 0s;
    animation-duration: 30s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 200px;
    height: 200px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
    animation-duration: 10s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 10s;
    animation-duration: 10s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 250px;
    height: 250px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 100px;
    height: 100px;
    animation-delay: 2s;
    animation-duration: 10s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 100px;
    height: 100px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 25%;
    }

    100%{
        transform: translateY(-150vh) rotate(720deg);
        opacity: 0.8;
        border-radius: 50%;
    }

}